import createPlugin from '@@/create-plugin';
import { createSubBook } from '@/lib/sub-book';
import { getPluginConfig } from '@@/use-config';
import { md5 } from 'topwrite';

export default createPlugin({
    components: {
        'editor:catalog:menu:item': () => import('./components/editor/menu-item'),
        'editor:catalog:items': () => import('./components/editor/items'),
        'editor:catalog:part:title': () => import('./components/editor/part-title'),
        'editor:catalog:part': () => import('./components/editor/part'),
        'page:asset': [() => import('./components/reader/asset'), { priority: 99 }],
        'page:menu:item': () => import('./components/reader/menu'),
        'activate:editor': () => import('./components/editor/activate'),
        'activate:reader': () => import('./components/reader/activate'),
    },
    async register(context, book) {

        const config = getPluginConfig(book.config);

        const subBook = createSubBook(config.getValue('host', location.origin));

        if (context === 'reader') {
            let sha: string[] = [];

            await Promise.all(book.summary.parts.map(async (part) => {
                const id = part.getMetadata('reference');
                if (id) {
                    const book = await subBook.getBook(id);
                    if (book) {
                        sha.push(book.sha);
                        const articles = await subBook.getArticles(book);
                        if (articles) {
                            part.articles = articles;
                        }
                    }
                }
            }));

            book.summary.indexLevels();

            if (sha.length > 0 && book.sha) {
                sha.push(book.sha);
                book.sha = md5(sha.sort().join(''));
            }

        }
    }
});
